import moment from 'moment'


jQuery(document).ready(function ($) {

  // MOBILE MENU
  $('.mobile-menu-icon').on('click', function () {
    $(".small-nav").toggleClass('active')
    $(".line").toggleClass('active')
    $("header").toggleClass('active')
  })

  $('.nav-button').on('click', function () {
    if ($('.menu-navigation-container').hasClass('active')) {
      $(".menu-navigation-container").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })


  $('#tour').addClass("active-page")
  $('#tour').addClass("active")
  $(".home-nav").fadeOut(500, function () {
    $(".small-nav").fadeIn(900)
    $("body").removeClass("home")
  })

  // Navigation
  $(".nav-btn").on('click', function (e) {
    e.preventDefault();
    let target = $(this).attr("href")

    $("main .active").fadeOut(500, function () {
      $(target).fadeIn(900)
      $(".active").removeClass("active")
      $(target).addClass("active")
    })

    $(".nav-btn").removeClass("active-page")
    $(this).addClass("active-page")

    if (target === "#home") {
      $(".small-nav").fadeOut(500, function () {
        $(".home-nav").fadeIn(900)
        $("body").addClass("home")
      })
    } else {
      $(".home-nav").fadeOut(500, function () {
        $(".small-nav").fadeIn(900)
        $("body").removeClass("home")
      })
    }
  });

  $("#main section:not(.active), .small-nav").hide()

  // // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_2467277/events?app_id=45PRESS_becky_g',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#tour-dates');
      let html = '';
      let n = 0
      if (data.length) {
        for (let event of data) {
          n++
          html += '<div class="event-group-wrapper">';
          html += '<div class="event-group">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD') + '</div>';
          html += '<div class="event-location-group">'
          html += '<div class="event-location">' + event.venue.location + '</div>';
          html += '<div class="event-venue">' + event.venue.name + '</div>';
          html += '</div>'
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link btn">' + offer.type + '</a>';
          }
          html += '</div>';
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>');
        events.addClass('no-scroll')
      }
      if (n < 9) {
        $("#toggle-dates").hide();
      }
    }
  });


  $('#toggle-dates').on('click', function (e) {
    e.preventDefault();

    if ($(this).html() === 'Show Less') {
      $(this).html('Show More');
      $('#tour-dates > *:gt(7)').fadeOut();
    } else {
      $(this).html('Show Less');
      $('#tour-dates > *').fadeIn();
    }
  });
});